
.paperlistContainer {
    margin-bottom: 10px;
    font-family: "Helvetica";
    line-height: 1.1rem;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    font-size: 0.8rem
}

.title {
    font-weight: 700
}

.journal {
    text-transform: capitalize;
    font-style: italic;
    color: red
}

.sortBy {
    font-size: 1.1rem;
}

.labSortBtn {
    height: 1.6rem;
    width: 100px;
    border-radius: 5px;
    border: 1px solid grey;
    margin-left: 5px;
}

.btnContainer {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-top:10px;
    justify-content: center;
}

.displayModeHeading {
    margin: 0 0 10px 0;
    text-align: center;
    color: var(--color-heading)
}

@media screen and (max-width: 450px) {
    .displayModeHeading {
        font-size: 1.1rem;
        text-align: center;
        width: 80%
    }
}

.noPapersMessage{
    text-align: center;
    font-size: 0.9rem;
    width: 85%;
    margin-top: 0;
}



.filters {
    display: flex;
    flex-direction: column;
    align-items: center;   
}

.filterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    justify-content: center;
    background-color: rgb(251, 239, 255);
    border-radius: 10px;
  }

  @media screen and (min-width: 451px) {
    .filterContainer{
        width: 30%;
    }
}


.filterSectionHeader {
    margin: 0;
    font-size: 0.85rem;
    color: rgb(126, 126, 126);
    margin-bottom: 5px;
}

  .filterItem{
      width: 300px
  }

  .drugFilterContainer {
      display: flex;
      justify-content: center;
  }

  .dateFilterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dateRangeInputContainer{
      display: flex;
      flex-direction: column;
  }

   .filterSubheading {
      margin: 0;
      padding-bottom: 5px;
      color: grey;
      font-size: 0.7rem;
  }

  .dateWarning {
      text-align: center;
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 11px
  }

.filterButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterButton {
    width: 150px;
    background-color: white;
    color: rgb(218, 143, 218);
    height: 1.5rem;
    border-radius: 10px;
    margin: 0 5px 10px 5px;
    border: 1px solid rgb(218, 143, 218);
}


  .filterButton:hover{
    cursor: pointer;
    background-color:  rgb(218, 143, 218);
    color:white;
    border: 1px solid   rgb(218, 143, 218);
  }

  .recencyDropdown {
      display: flex;
      justify-content: center;
      align-items: center;

  }

  .dateRangeButtonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .showDateRangeButton {
    background-color: white;
      color: lightseagreen;
      height: 1.5rem;
      width: auto;
      border-radius: 5px;
      border: 1px solid lightseagreen;
      padding: 0 10px;
      margin-bottom: 5px;
  }

  .showDateRangeButton:hover {
    background-color: rgb(93, 238, 231);
    color: white;
    border: 1px solid white;
}