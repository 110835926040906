.formLabelContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.formLabel {
    margin-bottom: 2px;
    color: grey;
    font-size: 0.7rem;
    margin-left: 10px;
}

.daysDropDown {
    width: 150px;
    border: 1px solid lightseagreen;
    height: 1.5rem;
    border-radius: 5px;
    color: lightseagreen;
    background-color: white;
    padding: 2px;
    margin: 0px 5px 5px 5px;
    font-size: 0.8rem;
}

@media screen and (max-width: 450px){
    .daysDropDown  {
        width: 125px;
        border: 1px solid lightseagreen;
        height: 1.4rem;
        border-radius: 5px;
        color: lightseagreen;
        padding: 2px;
        margin: 0px 5px 5px 5px;
        font-size: 0.7rem;
    }
}