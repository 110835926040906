.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Raleway', sans-serif;
}

:root {
  --color-heading: rgb(86, 0, 204);
  --color-heading-background: rgb(190, 147, 250);
}