.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.papersHomeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 451px) {
  .papersHomeContainer {
    width: 90%;
  }
}

.nav {
  width: 100%;
  border-bottom: 1px solid var(--color-heading)
}

.bannerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(-45deg, #eee952, #8c3ba7, #23a6d5, #05d3a3);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  height: 12vh;
  margin: 35px 0 0 0 ;
  padding: 0
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 450px) {
  .bannerContainer{
    font-size: 1.3rem;
    height: 8vh;
  }
}


.bannerHeading{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3rem;
  margin: 0;
}


@media screen and (max-width: 680px) {
  .bannerHeading {
    font-size: 2.1rem;
    height: 15vh;
  }
}
@media screen and (max-width: 450px) {
  .bannerHeading {
    font-size: 1.4rem;
    height: 15vh;
  }
}

.credit {
  font-size: 0.8rem;
  color: rgb(136, 136, 136);
  margin-top: 0;
  margin-bottom: 0;
}

.emailSpan {
  font-weight: bold;
}

.creditContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 0px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 0;
}

.loaderMessage {
  margin-top: 0;
  font-style: italic;
}

.fetchingText {
  color: black;
  text-align: center;
  margin-bottom: 0;
}

@media screen and (max-width: 400px) {
  .classicFilterHead {
    font-size: 1.1rem;
  }
}

.pageHeading {
  margin-top: 50px;
  margin-bottom: 5px;
  font-size: 1.8rem;
  color: var(--color-heading);
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 450px) {
  .pageHeading {
    font-size: 1.4rem;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .pageHeading {
    font-size: 1.3rem;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .pageHeading {
    font-size: 1.5rem;
    text-align: center;
  }
}

.pathwayHeading {
  margin-top: 0px;
}

.pageBlurb {
  text-align: justify;
  margin: 5px 0 0 0;
}

@media screen and (max-width: 400px) {
  .pageBlurb {
    margin-top: 0;
  }
}

.infoBlurb {
  width: 80%;
  text-align: justify;
  font-family: "Helvetica";
  line-height: 1.5rem;
  font-size: 1.1rem;
  margin: 0;
}

@media screen and (max-width: 740px) {
  .infoBlurb {
    margin-bottom: 0;
    font-size: 0.7rem;
    line-height: 0.9rem;
    width: 90%;
  }
}

.labCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 10px 0 0 0;
  font-size: 0.85rem;
}

@media screen and (max-width: 400px) {
  .labCardContainer {
    width: 85%;
    text-align: justify;
    margin: 5px 0 0 0;
    padding: 0;
  }
}

.datePlusHeading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 5px 0;
}

.yearHeading {
  font-weight: bold;
  margin: 10px 0 5px 0px;
  font-size: 1rem;
  color: var(--color-heading);
}

.pubmedLink {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.pathwayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}

.labsContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.labCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid grey;
  width: 100%;
  margin-bottom: 5px;
  padding: 2px;
  -webkit-box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  -moz-box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  border-radius: 7px;
  padding: 15px;
}

.labCardElements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin: 5px; */
}

.labCardHeading {
  font-weight: bold;
  margin: 5px 0;
  font-size: 1.1rem;
  color: var(--color-heading)
}

@media screen and (max-width: 450px) {
  .labCardHeading {
    font-size: 0.8rem;
    text-align: center;
  }
}

.labCardSubHeading {
  margin: 5px 0 0 0;
  font-weight: bold;
}

.labCardLabel {
  margin: 2px 0;
}

.cardBlurb {
  font-size: 0.75rem;
}

@media screen and (max-width: 450px) {
  .cardBlurb {
    font-size: 0.65rem;
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  justify-content: center;
}

.filterContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
  width: 90%;
}

@media screen and (max-width: 400px) {
  .filterContainer {
    margin-top: 10px;
  }
}

.labSortBtn {
  height: 2rem;
  width: 80px;
  border-radius: 5px;
  border: 1px solid grey;
  margin-left: 5px;
}

.paperSortBtn {
  height: 2rem;
  width: 100px;
  border-radius: 5px;
  border: 1px solid rgb(247, 159, 46);
  margin-left: 5px;
}

.sortLabel {
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 400px) {
  .sortLabel {
    font-size: 1rem;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filterHeading {
  margin-right: 10px;
  margin-top: 5px;
}

.classicsBtn {
  margin-bottom: 10px;
  margin-left: 5px;
  height: 1.8rem;
}

.classicsBtn:hover {
  background-color: black;
  color: white;
  border: none;
}

.dropDown {
  margin: 0 5px;
  height: 1.5rem;
  border-radius: 5px;
}

@media screen and (max-width: 740px) {
  .dropDown {
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 740px) {
  .countryDropDown {
    margin: 0 0 10px 0;
  }
}

option {
  color: rgb(245, 153, 47);
}

.filterLabel {
  margin-right: 8px;
}

.filterReset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allFilter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventItem {
  font-size: 0.9rem;
  margin: 0;
}

.timeline {
  z-index: -1;
}
/* 
.allResources {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.resourceContainer {
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 0;
  -webkit-box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  -moz-box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  box-shadow: 10px 10px 15px 0px rgba(168, 166, 168, 0.5);
  border-radius: 7px;
}

.resourceHeading {
  margin-bottom: 5px;
  color: steelblue;
}

.resource {
  margin: 5px 15px 5px 15px;
}

.resources {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  width: 100%;
}

.itemTitle {
  font-weight: bold;
  font-size: 1.1rem;
}

@media screen and (max-width: 400px) {
    .itemTitle {
        font-size: 1rem;
    }
} */

/* .resourceItem {
  display: flex;
  margin: 0;
  font-size: 0.9rem;
}

.resourceItem h4 {
  margin: 2px;
}

.resourceItem p {
  margin: 2px;
  text-align: justify;
}

.resourceItem a {
  margin: 2px;
}

.resourceItem p {
  margin: 3px;
}

.resourceRuler {
  border: 1px solid lightgrey;
  margin-bottom: 0;
} */

.platformContainer {
  margin-top: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
}

.sectionHeading {
  color: var(--color-heading);
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.resources {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resourcesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.resource {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
}

.resourceHead {
  font-weight: 700;
  margin: 0;
}

.resourceDescription {
  font-size: 0.85rem;
  margin: 3px 0;
}

.resourceLink {
  margin: 0;
  font-size: 0.85rem;
}

.resourceHeadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 10px 0 20px 0;
  font-size: 0.9rem;
}

.titleImage {
  display: flex;
}

.trialsHeading {
  text-align: center;
  margin-bottom: 10px;
}
.trialTitle {
  font-weight: 700;
  margin: 0 0 5px 0;
}

.trialDetail {
  font-size: 0.9rem;
  margin: 2px;
}

.trial {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
  text-align: justify;
}

.trials {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.rule {
  width: 100%;
  border: 1px solid light grey;
}

.trialsDropDown {
  width: 180px;
  height: 1.5rem;
  border-radius: 5px;
  margin-bottom: 10px;
}

.dropDownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trialsSearchFilterLabel {
  margin: 0 10px 10px 0;
}

.trialsSearchNameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.trialsSearchName {
  margin: 5px 0 2px 0;
}

.trialsSearchName2 {
  margin: 5px 0 2px 0;
  color: var(--color-heading);
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  text-align: justify;
  font-size: 0.8rem;
}